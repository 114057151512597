import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Typography,
  Modal,
  notification,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CommentOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getPlayalong, createPlayalong, deletePlayalong, updatePlayalong } from "../../services/yt-playalongs";
import AuthContext from "../../contexts/auth-context";
import { isValidYouTubeUrl } from "../../utils";
import FeedHeader from "../../components/typography/feed-header";
import { genreMap } from "../../enums/genres";

const genreOptions = Object.keys(genreMap).map((g) => {
  return {
    value: g,
    label: genreMap[g],
  };
});

const PlayalongEditLoading = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 1 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 3 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 10 }} />
        </Card>
      </Col>
    </Row>
  );
};

const SectionCardTitle = ({ title, desc }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      <Typography.Text type="secondary">
        <small>{desc}</small>
      </Typography.Text>
    </div>
  );
};

const YTPlayalongEditPage = () => {
  const navigate = useNavigate();
  const { playalongId } = useParams();
  const { token, tokenClaim } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [genre, setGenre] = useState("");
  const [url, setUrl] = useState("");

  const editOrNewTitle = playalongId ? "Edit YouTube Play Along" : "New YouTube Play Along";

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const playalongData = await getPlayalong(token, playalongId);

      if (playalongData.user.username !== tokenClaim.username) {
        navigate("/");
      }

      const {
        title,
        description,
        url,
        genre,
      } = playalongData;

      setTitle(title);
      setDescription(description);
      setUrl(url)
      setGenre(genre)
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSavePlayalong = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const playalongData = {
        title,
        description,
        url,
        genre,
      };

      if (playalongId) {
        console.log(playalongId)
        await updatePlayalong(token, playalongId, playalongData);
      } else {
        await createPlayalong(token, playalongData);
      }

      notification.open({
        message: "YouTube Play Along Saved Successfully",
        description: "Note that it will not be visible until we have successfully extracted the chords.",
        placement: "bottomRight",
      });
      navigate("/yt-playalongs/recent");
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to save this blog at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    try {
      await deletePlayalong(token, { id: playalongId });
      notification.open({
        message: "YouTube Play Along Deleted",
        description:
          `${title} has been deleted.`,
        placement: "bottomRight",
      });
      navigate(`/yt-playalongs/`);
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to delete this Play Along at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    if (playalongId) {
      loadData();
    } else {
      setIsLoading(false);
    }
  }, [playalongId]);

  if (hasError) {
    return (
      <Alert
        type="error"
        message="Something went wrong..."
        description="An error occured, we are unable to load the Play Along. Please try again
        later."
      />
    );
  }
  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <FeedHeader icon={<CommentOutlined />} title={editOrNewTitle} />
        </Col>
      </Row>
      {isLoading ? (
        <PlayalongEditLoading />
      ) : (
        <Form
          initialValues={{
            remember: true,
            title,
            description,
            url,
            genre
          }}
          onFinish={onSavePlayalong}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Card size="small">
                <Typography.Title level={5}>Title</Typography.Title>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please add a title.",
                    },
                  ]}
                  style={{ margin: 0 }}
                >
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxLength={400}
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24}>
              <Card size="small">
                <SectionCardTitle
                  title="Description"
                  desc="A brief description of your blog that entices users to read it."
                />
                <Form.Item
                  name="description"
                  style={{ margin: 0 }}
                >
                  <Input.TextArea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={500}
                    style={{ marginBottom: 12 }}
                    showCount
                    autoSize
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col span={24}>
              <Card size="small">
                <SectionCardTitle
                  title="Genre"
                  desc="Indicate the musical genre of this video."
                />
                <Form.Item
                  name="genre"
                  rules={[
                    {
                      required: true,
                      message: "Please input a genre!",
                    },
                  ]}
                >
                  <Select
                    value={genre}
                    onChange={(e) => setGenre(e)}
                    placeholder="Select genre"
                    options={genreOptions}
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col span={24}>
              <Card size="small">
                <SectionCardTitle
                  title="YouTube URL"
                  desc="Copy and paste the URL of the YouTube Video below."
                />
                <Form.Item
                  name="url"
                  rules={[
                    {
                      required: true,
                      message: "Please add a valid url.",
                    },
                  ]}
                  style={{ margin: 0 }}
                >
                  <Input
                    value={description}
                    onChange={(e) => setUrl(e.target.value)}
                    maxLength={500}
                  />
                </Form.Item>
              </Card>
            </Col>

            <Col span={24} style={{ textAlign: "right" }}>
              <Row justify="space-between">
                <Col>
                  {playalongId && (
                    <>
                      <Button danger onClick={() => setDeleteModal(true)} type="text">
                        Delete
                      </Button>
                      <Modal
                        open={deleteModal}
                        title="Delete YouTube Play Along"
                        onCancel={() => setDeleteModal(false)}
                        onOk={onConfirmDelete}
                        okText="Delete"
                        okType="danger"
                      >
                        <Typography.Text>Are you sure you want to delete the play along <b>{title}</b>?</Typography.Text>
                      </Modal>
                    </>
                  )}
                </Col>
                <Col>
                  <Space>
                    <Button onClick={() => navigate("/yt-playalongs/recent")}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !isValidYouTubeUrl(url)
                      }
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default YTPlayalongEditPage;
