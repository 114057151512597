import React, { useState, useContext, useEffect } from "react";
import { Layout, Col, Row, theme, Button, Badge, Slider, Space } from "antd";
import { OrderedListOutlined, TableOutlined } from "@ant-design/icons";
import QueueDrawer from "../../queue-drawer/queue-drawer";
import MusicPlayerContext from "../../../contexts/music-player-context";
import NowPlaying from "../now-playing";
import AudioControls from "../audio-controls/audio-controls";
import Container from "../container/container";
import CurrentNoteModal from "../../current-note-modal/current-note-modal";
import { convertToMinutes } from "../../../utils";
import mediaItemTypes from "../../../enums/media-item-types";

const { Header } = Layout;

const ControlsHeader = () => {
  const {
    current,
    queue,
    currentNote,
    trackProgress,
    trackDuration,
    setTrackProgress,
  } = useContext(MusicPlayerContext);
  const [isQueueDrawerOpen, setIsQueueDrawerOpen] = useState(false);
  const [isCurrentNoteOpen, setIsCurrentNoteOpen] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    setIsCurrentNoteOpen(false);
  }, [current]);

  return (
    <>
      <Header
        style={{
          height: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          position: "sticky",
          top: 0,
          zIndex: 997,
          width: "100%",
          background: colorBgContainer,
          borderBottom: "1px solid lightgray",
        }}
      >
        <Container>
          <Row gutter={[16, 16]} justify="space-between">
            <Col xs={{ span: 11 }} lg={{ span: 6 }}>
              <AudioControls tracks={queue} current={current} />
            </Col>
            <Col xs={{ span: 0 }} lg={{ span: 10 }}>
              <NowPlaying currentTrack={current} />
            </Col>
            <Col
              xs={{ span: 13 }}
              lg={{ span: 6 }}
              style={{ textAlign: "right" }}
            >
              <Space>
                <Button
                  onClick={() => setIsCurrentNoteOpen(true)}
                  disabled={
                    !current ||
                    !current.is_transcribed ||
                    current.hide_transcription
                  }
                >
                  <Space>
                    <>
                      <TableOutlined />
                    </>
                    Live Chords
                  </Space>
                </Button>
                <CurrentNoteModal
                  isOpen={isCurrentNoteOpen}
                  onCancel={() => setIsCurrentNoteOpen(false)}
                  currentNote={currentNote}
                />
                <Badge count={queue.length}>
                  <Button
                    onClick={() => setIsQueueDrawerOpen(true)}
                    type="text"
                  >
                    <OrderedListOutlined />
                  </Button>
                </Badge>
              </Space>

              <QueueDrawer
                isOpen={isQueueDrawerOpen}
                onClose={setIsQueueDrawerOpen}
              />
            </Col>
          </Row>
        </Container>
        {current && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              bottom: -18,
              zIndex: 1,
            }}
          >
            {false && current?.type === mediaItemTypes.JAMTRACK && (
              <Slider
                value={trackProgress}
                min={1}
                max={trackDuration - 1}
                onChange={(seconds) => setTrackProgress(seconds)}
                tooltip={{ formatter: (value) => convertToMinutes(value) }}
                style={{
                  paddingLeft: 0,
                  marginLeft: 0,
                  paddingRight: 0,
                  marginRight: 0,
                }}
                railStyle={{
                  backgroundColor: "rgb(211, 211, 211)",
                  borderRadius: 0,
                  height: 3,
                }}
                trackStyle={{ borderRadius: 0, height: 3 }}
                handleStyle={{ display: "None" }}
              />
            )}
          </div>
        )}
      </Header>
    </>
  );
};

export default ControlsHeader;
